import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  CareerRole_PathwayLevel as PathwayLevel,
  Specialization_DifficultyLevel as SpecializationDifficultyLevel,
} from '__generated__/graphql-types';
import type { CareerRole_MerchandisingJobCategory as JobCategory } from '__generated__/graphql-types';

import CareerRolesCollectionQuery from 'bundles/unified-common/queries/CareerRolesCollection.graphql';
import type {
  CareerRolesCollectionFragment,
  CareerRolesCollectionQueryQuery as CareerRolesCollectionQueryType,
} from 'bundles/unified-common/queries/__generated__/CareerRolesCollection';
import type { CareerRolesCollectionType } from 'bundles/unified-common/types/careerRoles';

const RECOMMENDER_CONFIGURATION_ID = 'roles-for-level-and-category';

export const normalizeCareerRolesCollection = (
  content?: CareerRolesCollectionFragment
): CareerRolesCollectionType | undefined => {
  if (!content) {
    return undefined;
  }

  const collection = {
    ...content,
    entities: content?.entities?.elements
      .map((entity) => {
        if (!entity || entity.__typename !== 'Skill_CareerRole') {
          return null;
        }

        const pathways = {
          ...entity?.pathways,
          entities: entity?.pathways?.entities?.elements
            .map((pathway) => {
              if (
                pathway?.__typename !== 'ProductCard_ProductCard' ||
                pathway?.productTypeAttributes?.__typename !== 'ProductCard_Specialization'
              ) {
                return null;
              }

              const product = pathway?.productTypeAttributes?.canonical;

              const proficiencyLevel = product?.isCareerGateway
                ? SpecializationDifficultyLevel.Beginner
                : product?.difficultyLevel;

              return {
                ...product,
                proficiencyLevel,
              };
            })
            .filter(Boolean),
        };

        return {
          ...entity,
          pathways,
        };
      })
      .filter(Boolean),
  };

  return collection as CareerRolesCollectionType;
};

function useCareerRolesCollection({
  ssr,
  skip,
  limit = 12,
  level,
  jobCategory: category,
}: {
  ssr: boolean;
  skip: boolean;
  limit?: number;
  level?: PathwayLevel;
  jobCategory?: JobCategory;
}) {
  const { data, loading } = useQuery<CareerRolesCollectionQueryType>(CareerRolesCollectionQuery, {
    variables: {
      queryCollectionId: RECOMMENDER_CONFIGURATION_ID,
      additionalInput: {
        level: level ?? PathwayLevel.Beginner,
        ...(category ? { category } : {}),
      },
      limit,
    },
    notifyOnNetworkStatusChange: true,
    ssr,
    skip,
    errorPolicy: 'all' as const,
    context: { clientName: 'gatewayGql' },
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: `Error fetching Pathway career roles collection with the recommender ID "${RECOMMENDER_CONFIGURATION_ID}"`,
        },
      }),
  });

  return {
    data: normalizeCareerRolesCollection(data?.Collection?.queryCollection),
    loading,
  };
}

export default useCareerRolesCollection;
