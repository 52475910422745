import type React from 'react';
import { memo, useMemo } from 'react';

import { compose } from 'recompose';

import type { EnrichedCatalogResultQuery } from 'bundles/browse/components/CatalogResultUtils';
import withPremiumProductCollections from 'bundles/browse/components/Domain/withPremiumProductCollections';
import type { DiscoveryCollection } from 'bundles/browse/components/types/DiscoveryCollections';
import { PRODUCT_TYPE_NAMES, ProductVariantNames } from 'bundles/browse/constants';
import type { DegreeProductMember } from 'bundles/browse/types/degree-list';
import type { CarouselCollection } from 'bundles/collections-carousel/types/CarouselCollection';
import DegreesDiscoveryExperiments from 'bundles/epic/clients/DegreesDiscovery';
import GrowthPremiumExperiments from 'bundles/epic/clients/GrowthPremium';
import { getGenAICollectionSets } from 'bundles/front-page/constants/genAICollectionSets';
import type { PropsFromWithMediaMaxWidthScreenTablet } from 'bundles/front-page/hoc/WithMediaMaxWidthTablet';
import { withMediaMaxWidthScreenTablet } from 'bundles/front-page/hoc/WithMediaMaxWidthTablet';
import withUserAgentFromContext from 'bundles/front-page/hoc/withUserAgentFromContext';
import useDiscoveryCollectionsData from 'bundles/front-page/hooks/useDiscoveryCollectionsData';
import useStackableCollectionData from 'bundles/front-page/hooks/useStackableCollectionData';
import useCareerRolesCollection from 'bundles/unified-common/hooks/useCareerRolesCollection';
import type { CareerRolesCollectionType } from 'bundles/unified-common/types/careerRoles';
import { getPathwaysSessionBasedVariant } from 'bundles/unified-common/utils/showPathways';

type InnerProps = {
  regionSlug?: string;
  language?: string;
  certificatesOnly?: boolean;
  skip?: boolean;
  children: (data: FrontPageCollectionsDataState) => JSX.Element | null;
};

type PropsFromPremiumProductCollections = {
  degreeListFromPremiumProductsCollection?: DegreeProductMember[];
  mastertrackListFromPremiumProductsCollection?: DegreeProductMember[];
  universityCertificateListFromPremiumProductsCollection?: DegreeProductMember[];
};

export type FrontPageCollectionsDataState = {
  collectionsLoading?: boolean;
  premiumProductCollections?: EnrichedCatalogResultQuery[];
  matches: boolean;
  discoveryCollections?: DiscoveryCollection[];
  careerRolesCollection?: CareerRolesCollectionType;
};

type Props = InnerProps & PropsFromWithMediaMaxWidthScreenTablet & PropsFromPremiumProductCollections;

const isNotNull = <T,>(el: T | null): el is T => el !== null;

const METADATA_KEY_REGEX = new RegExp(/:(:)?\w+/gi);

export const LOHP_PREMIUM_PRODUCT_COLLECTIONS_VARIABLES = {
  domains: null,
  productVariants: [
    ProductVariantNames.BachelorsDegree,
    ProductVariantNames.MastersDegree,
    ProductVariantNames.PostgraduateDiploma,
    ProductVariantNames.Mastertrack,
    ProductVariantNames.UniversityCertificate,
  ],
  ssr: true,
};

export const getCollectionKey = (
  collection: EnrichedCatalogResultQuery | CarouselCollection | DiscoveryCollection
): string | null => {
  const hasEnrichedCatalogResultQueryOrDiscoveryCollectionId = (
    checkCollection: EnrichedCatalogResultQuery | CarouselCollection | DiscoveryCollection
  ): checkCollection is EnrichedCatalogResultQuery | DiscoveryCollection => {
    return !!checkCollection && 'id' in checkCollection;
  };

  const hasCarouselCollectionId = (
    checkCollection: EnrichedCatalogResultQuery | CarouselCollection | DiscoveryCollection
  ): checkCollection is CarouselCollection => {
    return !!checkCollection && 'collectionId' in checkCollection;
  };

  if (
    !collection ||
    (!hasEnrichedCatalogResultQueryOrDiscoveryCollectionId(collection) && !hasCarouselCollectionId(collection))
  ) {
    return null;
  }

  let metadataKeys: RegExpMatchArray | null = null;
  if (hasEnrichedCatalogResultQueryOrDiscoveryCollectionId(collection)) {
    metadataKeys = collection.id.match(METADATA_KEY_REGEX);
  } else if (hasCarouselCollectionId(collection)) {
    metadataKeys = collection.collectionId.match(METADATA_KEY_REGEX);
  }

  if (!metadataKeys || metadataKeys.length === 0) {
    return null;
  }

  return metadataKeys[metadataKeys.length - 1].replace(/[:]+/g, '');
};

export const immutableSplice = <T,>(arr: T[], replacementIndex: number, newElement: T): T[] => {
  return [...arr.slice(0, replacementIndex), newElement, ...arr.slice(replacementIndex + 1)];
};

export const immutableInsert = <T,>(collections: T[], index: number, newItems: T[]): T[] => {
  return index === -1
    ? [...collections, ...newItems]
    : [...collections.slice(0, index + 1), ...newItems, ...collections.slice(index + 1)];
};

export const getCollectionsData = (
  discoveryCollections: DiscoveryCollection[],
  certificatesOnly: boolean,
  degreesList?: DegreeProductMember[]
) => {
  const nullResult = {
    exploreDegreeCollection: {} as EnrichedCatalogResultQuery,
    collectionsLoading: true,
  };

  if (!degreesList || !discoveryCollections) {
    return nullResult;
  }

  const degreeElements = degreesList;
  const degreeCollectionIndex = discoveryCollections.map(getCollectionKey).indexOf('degrees');
  let exploreDegreeCollection: EnrichedCatalogResultQuery;

  if (degreeElements && !certificatesOnly && degreeCollectionIndex !== -1) {
    const degreeList: DegreeProductMember[] = [];
    degreeElements.forEach((degreeProduct) => {
      if (degreeProduct?.partners?.elements && degreeList.length < 12) {
        degreeList.push({
          ...degreeProduct,
          type: PRODUCT_TYPE_NAMES.DEGREE,
          link: degreeProduct.link,
          partner: degreeProduct.partner,
        });
      }
    });

    // Merge degree entries with preset collection data
    const degrees = degreeList?.map((degree) => ({ degree, __typename: 'DegreeListsV3' }));

    exploreDegreeCollection = {
      entries: degrees,
      label: '::DEGREES',
      id: 'RECONFIGURABLE_COMPONENTS:degrees',
      linkedCollectionPageMetadata: null,
      debug: null,
      __typename: 'DegreeListsV3',
    } as unknown as EnrichedCatalogResultQuery;
  }

  return {
    exploreDegreeCollection,
    collectionsLoading: false,
  };
};

export const getCollectionId = ({ language, regionSlug }: Required<Pick<InnerProps, 'language' | 'regionSlug'>>) => {
  const collectionIdParticles = ['lohp'];
  const lowercasedRegionSlug = regionSlug.toLowerCase();
  const lowercasedLanguage = language.toLowerCase();

  // Currently, the collections available are lohp-global-en, lohp-global-es, lohp-in-en, lohp-in
  if (lowercasedRegionSlug === 'in') {
    collectionIdParticles.push('in');
    if (lowercasedLanguage === 'en') {
      collectionIdParticles.push('en');
    }
  } else {
    collectionIdParticles.push('global');
    if (lowercasedLanguage === 'es') {
      collectionIdParticles.push('es');
    } else {
      collectionIdParticles.push('en');
    }
  }

  return collectionIdParticles.join('-');
};

export const lohpDegreeListsV3Options = () => {
  const fetchPolicy = 'cache-and-network' as const;
  return { fetchPolicy };
};

const WithCollectionDataInnerComponent: React.FC<Props> = memo(({ children, ...otherProps }) => {
  const {
    certificatesOnly,
    degreeListFromPremiumProductsCollection,
    mastertrackListFromPremiumProductsCollection,
    universityCertificateListFromPremiumProductsCollection,
    language,
    regionSlug,
  } = otherProps;
  const LOHPpathwayS12nGraduallyRolloutEpic = DegreesDiscoveryExperiments.preview('LOHPpathwayS12nGraduallyRollout');

  const stackableData = useStackableCollectionData({
    skipDegree: false,
    skipS12n: !LOHPpathwayS12nGraduallyRolloutEpic,
  });
  const contextId = getCollectionId({
    language: language || 'en',
    regionSlug: regionSlug || 'us',
  });
  const discoveryCollectionsData = useDiscoveryCollectionsData({ contextId, contextType: 'LOHP' });
  const discoveryCollections = discoveryCollectionsData.collections.filter(isNotNull);

  const { data: careerRolesCollection, loading: careerRolesCollectionLoading } = useCareerRolesCollection({
    ssr: true,
    skip: getPathwaysSessionBasedVariant() === 'control',
    limit: 3,
  });

  const { exploreDegreeCollection, collectionsLoading } = useMemo(
    () => getCollectionsData(discoveryCollections, certificatesOnly ?? false, degreeListFromPremiumProductsCollection),
    [discoveryCollections, certificatesOnly, degreeListFromPremiumProductsCollection]
  );

  // prefetch GenAICollections
  const genAICollectionSets = getGenAICollectionSets();
  useDiscoveryCollectionsData({
    contextId: genAICollectionSets[0].contextId as string,
    contextType: genAICollectionSets[0].context as string,
  });

  let premiumProductCollections = [exploreDegreeCollection];

  // Need to get the degree and s18 index again
  let degreeCollectionIndex = discoveryCollections.map(getCollectionKey).indexOf('degrees');

  if (stackableData.stackableDegrees.length !== 0) {
    const stackableDegreesResultQuery: EnrichedCatalogResultQuery = {
      // @ts-expect-error - just temporarily ignore
      entries: stackableData.stackableDegrees.map((degree) => ({ degree })),
      label: '::STACKABLE_DEGREES',
      id: 'RECONFIGURABLE_COMPONENTS:stackable_degrees',
      linkedCollectionPageMetadata: null,
      debug: 'false',
      __typename: 'PremiumProductCollection',
    };

    // show the stackable degree module on top of the degree module
    premiumProductCollections = [stackableDegreesResultQuery, ...premiumProductCollections];
  }

  if (stackableData.stackableSpecializations.length !== 0) {
    const stackableCoursesAndCertificatesResultQuery: EnrichedCatalogResultQuery = {
      // @ts-expect-error - just temporarily ignore
      entries: stackableData.stackableSpecializations.map((degree) => ({ degree })),
      label: '::STACKABLE_SPECIALIZATIONS',
      id: 'RECONFIGURABLE_COMPONENTS:stackable_specializations',
      linkedCollectionPageMetadata: null,
      debug: 'false',
      __typename: 'PremiumProductCollection',
    };

    // show stackable specializations on top of stackable degrees
    const stackableDegreesIndex = discoveryCollections.map(getCollectionKey).indexOf('stackable_degrees');
    premiumProductCollections = immutableInsert(premiumProductCollections, stackableDegreesIndex + 1, [
      stackableCoursesAndCertificatesResultQuery,
    ]);
  }

  // Need to update the degree index since the stackable degree module is inserted on top
  degreeCollectionIndex = discoveryCollections.map(getCollectionKey).indexOf('degrees');

  if (GrowthPremiumExperiments.get('showMasterTracksAndUcertsOnHomepage') === true) {
    // TODO: add two collections below to LOHPCollectionsQuery
    // Manually adding mastertrack and ucert items for mastertrack and certificate discovery experiment
    const mastertrackCatalogResultQuery: EnrichedCatalogResultQuery = {
      // @ts-expect-error - just temporarily ignore
      entries: mastertrackListFromPremiumProductsCollection.map((degree) => ({ degree })),
      label: '::MASTERTRACKS',
      id: 'RECONFIGURABLE_COMPONENTS:mastertracks',
      linkedCollectionPageMetadata: null,
      debug: 'false',
      __typename: 'PremiumProductCollection',
    };

    const universityCertificateResultQuery: EnrichedCatalogResultQuery = {
      // @ts-expect-error - just temporarily ignore
      entries: universityCertificateListFromPremiumProductsCollection?.map((degree) => ({ degree })),
      label: '::UNIVERSITY_CERTIFICATES',
      id: 'RECONFIGURABLE_COMPONENTS:university_certificates',
      linkedCollectionPageMetadata: null,
      debug: 'false',
      __typename: 'PremiumProductCollection',
    };

    premiumProductCollections = immutableInsert(premiumProductCollections, degreeCollectionIndex, [
      mastertrackCatalogResultQuery,
      universityCertificateResultQuery,
    ]);
  }

  return children({
    ...otherProps,
    collectionsLoading: collectionsLoading || careerRolesCollectionLoading,
    premiumProductCollections,
    discoveryCollections,
    careerRolesCollection,
  });
});

WithCollectionDataInnerComponent.displayName = 'WithCollectionDataInnerComponent';

export const WithCollectionData = compose<Props, InnerProps>(
  withPremiumProductCollections(() => LOHP_PREMIUM_PRODUCT_COLLECTIONS_VARIABLES),
  withUserAgentFromContext(),
  withMediaMaxWidthScreenTablet
)(WithCollectionDataInnerComponent);
